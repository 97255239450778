<template>
  <div class="ma-4">
    <h4 v-if="serviceName === 'Jira'">
      {{ $t("webhooks.jiraHeaderMessage") }}
    </h4>
    <h4 v-else-if="serviceName === 'Toggl Track'">
      {{ $t("webhooks.togglHeaderMessage") }}
    </h4>
    <h4 v-else-if="serviceName === 'Redmine'">
      {{ $t("webhooks.redmineHeaderMessage") }}
    </h4>

    <p
      v-if="serviceName === 'Jira'"
      @click="copyJiraURI"
      style="cursor: pointer"
    >
      {{ jiraURI }}
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-if="!coppied" v-on="on"> mdi-content-copy</v-icon>
            <v-icon v-else v-on="on"> mdi-check</v-icon>
          </template>
          {{ $t("toolTips.copy") }}
        </v-tooltip>
      </template>
    </p>
    <p v-if="serviceName === 'Jira'">
      {{ $t("webhooks.enable") }} <b>created</b>, <b>updated</b>
      {{ $t("webhooks.and") }} <b>deleted</b> {{ $t("webhooks.for") }}
      <b>Issue</b>, <b>Worklog</b> {{ $t("webhooks.and") }} <b>Project</b>.
    </p>

    <div v-if="serviceName === 'Toggl Track'">
      <v-btn density="compact" @click="createTogglWebhook">{{
        $t("webhooks.createWebhookBtn")
      }}</v-btn>
      <!-- eslint-disable-next-line -->
      <v-progress-circular
        v-if="togglStatusLoading"
        indeterminate
        color="primary"
        size="20"
        width="2"
        v-on="on"
      ></v-progress-circular>
      <v-icon v-else-if="togglStatusSuccess" color="success" v-on="on">
        mdi-check</v-icon
      >
      <v-icon v-else-if="togglStatusDone" color="warning" v-on="on">
        mdi-alert-circle-check-outline</v-icon
      >
      <v-icon v-else-if="togglStatusError" color="error" v-on="on">
        mdi-close</v-icon
      >
      <p>
        {{ $t("webhooks.deleteTogglWebhook") }}
        <a
          :href="togglTrackWebhooksLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          Toggl Track</a
        >.
      </p>
    </div>
    <v-snackbar right top app color="warning" v-model="togglStatusDone">
      {{ $t("notificationSnackbars.togglWebhookDone") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="togglStatusDone = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="success" v-model="togglStatusSuccess">
      {{ $t("notificationSnackbars.togglWebhookSuccess") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="togglStatusSuccess = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="togglStatusError">
      {{ $t("notificationSnackbars.togglWebhookError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="togglStatusError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { Constants } from "../../../constants/Constants";
import { ToolType } from "../../../enums/tools/typeOfTool";
import { getInstance } from "../../../auth/auth0-plugin";
export default {
  props: ["connectionBox", "toolNum"],
  data() {
    return {
      jiraURI: `${Constants.BACKEND_API_URL}webhooks/jira/${this.connectionBox.databaseId}`,
      coppied: false,

      togglStatusDone: false,
      togglStatusSuccess: false,
      togglStatusError: false,
      togglStatusLoading: false,
    };
  },
  computed: {
    serviceName() {
      return this.toolNum == 1
        ? this.connectionBox.firstTool.tool
        : this.connectionBox.secondTool.tool;
    },
    togglTrackWokspace() {
      return this.toolNum == 1
        ? this.connectionBox.firstTool.selectedTogglTrackWorkspace
        : this.connectionBox.secondTool.selectedTogglTrackWorkspace;
    },
    togglTrackWebhooksLink() {
      return `https://track.toggl.com/${this.togglTrackWokspace}/integrations/webhooks`;
    },
  },
  methods: {
    copyJiraURI() {
      this.coppied = true;
      navigator.clipboard.writeText(this.jiraURI);
      setTimeout(() => {
        this.coppied = false;
      }, 2000);
    },
    async createTogglWebhook() {
      this.togglStatusLoading = true;
      const authService = getInstance();
      const accessToken = await authService.getTokenSilently();
      const apiKey =
        this.toolNum == 1
          ? this.connectionBox.firstTool.togglTrackApiKey
          : this.connectionBox.secondTool.togglTrackApiKey;
      const workspaceId = this.togglTrackWokspace;
      const connectionId = this.connectionBox.databaseId;
      const data = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const body = {
        api_key: apiKey,
        workspaceId: workspaceId,
        connectionId: connectionId,
        backendURL: Constants.BACKEND_API_URL,
      };

      await axios
        .post(
          `${Constants.BACKEND_API_URL}synced_services_config/toggl_track_create_webhook`,
          body,
          data
        )
        .then((response) => {
          if (response.status === 201) {
            this.togglStatusLoading = false;
            this.togglStatusSuccess = true;
          } else if (response.status === 202) {
            this.togglStatusLoading = false;
            this.togglStatusDone = true;
          } else {
            this.togglStatusLoading = false;
            this.togglStatusError = true;
          }
        })
        .catch((error) => {
          this.togglStatusLoading = false;
          this.togglStatusError = true;
        });
    },
  },
};
</script>